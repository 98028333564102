
/* eslint-disable @typescript-eslint/no-unused-vars */
import { mapMutations } from 'vuex';
import moment from 'moment';
import ApiService from '@/services/api-service.js';
import TripWarningList from '@/pages/TripWarningList';
import map from '@/components/GoogleMap';
import VideoDialog from '@/components/dialog/VideoDialog';
import { guageColor } from '@/plugins/config';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import TDRoutesOnMap from '@/components/TDRoutesOnMap';

export default {
  name: 'Trip',
  components: {
    TDRoutesOnMap,
    gmap: map,
    TripWarningList,
    VideoDialog,
    Treeselect: () => {
      if (process.client) {
        return import('@riophae/vue-treeselect');
      }
    },
  },
  filters: {
    // 時間格式
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    formatDateYYMM(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    formatTime(date) {
      return moment(date).format('HH:mm:ss');
    },
    kmFilter(value) {
      return Number(value).toFixed(2);
    },
  },

  props: {
    // google map api 初始值
    center: {
      type: Object,
      default: () => ({ lat: 22.3186, lng: 114.1796 }),
    },
  },
  data() {
    return {
      usedTagColor: [],
      // ********************************
      vehicleType: 'UNKNOWN',
      playRouteBtnDisabled: false,
      stopRouteBtnDisabled: true,
      getOptions: [],
      optionType: [],
      optionsets: [],
      optionsList: [],
      banner: false,
      markList: [],
      semarkList: [],
      polyList: [],
      colorIndex: 0,
      selectedItem: null,
      selectedcolor: null,
      noRecord: false,
      tripLoading: false,
      // ***************       初始資料      ***************
      valid: true,
      tab: null,
      picker: '',
      perPage: 10,
      // video 開關
      videoDialog: false,
      // video的值
      media: '',
      // isBSD , rotate video
      isBSD: false,
      // 錯誤訊息提示
      tripErrorFilter: false,
      tripErrorMessage: '',
      // 縮放Trip List drawer
      tripDrawer: true,
      // 車輛的狀態
      driverType: '',
      // 回到Warning List 頁面
      jumpWarningListPage: false,
      // company group 下拉式選單
      companyGroup: '',
      // vehicle 下拉式選單
      vehicle: [],
      vehicles: [],
      checkVehicles: [],
      dates: [
        new Date(new Date().setDate(new Date().getDate() - 7)),
        new Date(new Date().setDate(new Date().getDate())),
      ],
      // 時速
      distance: 0,
      // trip list data
      tripList: [],
      // trip list data current
      currentPage: 1,
      // trip lis 資料
      getTripList: [],
      // 全選選擇器開關
      selectorSwitch: true,
      // select 搜尋㑌
      filterInput: [],
      // ***************Warning List drawer***************
      // waring drawer width
      warningWidth: 415,
      // 所有 warning 資料
      warningItem: [],
      // 改變顏色資料
      changeColor: false,
      changeColorIndex: 0,
      // 控制warning drawer開關
      warningDrawer: false,
      filterWarningList: [],
      // *************************************************
      historyIndex: false,
      historyDrawer: false,
      historyList: [],
      currentHistory: 1,
      getHitoryList: [],
      currentPlate: '',
      normalizer(node) {
        if (node.children == null || node.children.length === 0) {
          delete node.children;
        }
        return {
          id: node.name,
          label: node.name,
          children: node.children,
        };
      },
      requiredRules: [
        (v) => {
          if (Array.isArray(v)) {
            return !!(v && v.length) || this.$t('Required');
          }
          return !!v || this.$t('Required');
        },
      ],
      treeselectFieldValid: false,
    };
  },
  computed: {
    treeselectClass() {
      return {
        'treeselect-invalid': !this.treeselectFieldValid,
      };
    },
    // vehicle list 選擇全部
    likesAllItems() {
      return this.getOptions.length === this.optionsList.length;
    },
    likesSomeItems() {
      return this.getOptions.length > 0 && !this.likesAllItems;
    },
    // 改變 vehicle list 選擇全部 icon
    icon() {
      if (this.likesAllItems) return 'mdi-close-box';
      if (this.likesSomeItems) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },
  watch: {
    polyList(val) {
      if (val.length > 0) {
        if (this.$store.state.app.historyRoute) {
          this.historyIndex = true;
        }
      } else {
        this.historyIndex = false;
        this.historyDrawer = false;
      }
    },

    // 監控 warning Item 並重新渲染
    warningItem(newValue) {
      this.setMapList(newValue);
    },
    optionType(val) {
      this.optionsList = [];
      // this.getOptions = [];
      if (
        val.value === 'driverdepartment' ||
        val.value === 'vehicledepartment'
      ) {
        this.getOptions = null;
      } else {
        this.getOptions = [];
      }
    },
    // Change company
    '$store.state.app.company'(val) {
      this.noRecord = false;
      this.optionType = [];
      this.tripList = [];
      this.getTripList = [];
      this.historyList = [];
      this.getHistoryList = [];
      this.banner = false;
      if (this.warningItem.length > 0) {
        this.warningItem = [];
      }
    },
    getHistoryList(val) {
      this.stopRoute();
      this.playRouteBtnDisabled = val.length === 0;
    },
    historyDrawer(val) {
      if (!val) {
        this.stopRoute();
      }
    },
  },
  beforeDestroy() {
    this.stopRoute();
  },
  mounted() {
    this.setOptions();
    if (window.opener != null) {
      setTimeout(() => {
        const clickedQuery = JSON.parse(localStorage.openTrip);
        this.submit(parseInt(clickedQuery.TripId));
      }, 1000);
    }
  },
  methods: {
    ...mapMutations('app', ['getPloyline', 'getMarker', 'getMobileLatLng']),
    getLang(item) {
      return this.$t(item.text);
    },
    validateTreeselect() {
      this.treeselectFieldValid = this.getOptions.length > 0;
    },
    onChange() {
      this.getOptionList(this.optionType.url);
      this.$refs.form.validate();
    },
    getOptionList(url) {
      ApiService.getWarningDataOptionList(this.$axios, url).then((response) => {
        if (response.items) {
          response.items.forEach((item) => {
            this.optionsList.push(item);
          });

          // if opened by parent
          if (window.opener != null) {
            try {
              const clickedQuery = JSON.parse(localStorage.openTrip);
              // value
              this.optionType.value = 'vehicle';
              // items
              this.getOptions = [
                `${clickedQuery.plate} [${clickedQuery.vdept}]`,
              ];
              const d = new Date(clickedQuery.utcts * 1000);
              this.dates = [d, d];

              console.log(this.dates);
            } catch (e) {
              console.log(e);
            }
          }
        }
      });
    },
    disabledBefore(date) {
      return this.warningDatePickerDisableDate(date);
    },
    disabledAfter() {
      return new Date();
    },
    setOptions() {
      this.optionsets = [];
      ApiService.getWarningDataOptions(this.$axios).then((response) => {
        if (response.items) {
          response.items.forEach((item) => {
            switch (item.name) {
              case 'Driver':
              case 'Driver Group':
              case 'Driver Department':
              case 'Vehicle':
              case 'Vehicle Group':
              case 'Vehicle Department':
                this.optionsets.push({
                  text: item.name,
                  value: item.value,
                  type: item.type,
                  url: item.url,
                  active: true,
                  disabled: false,
                });
                break;
              default:
                break;
            }
          });
          // default
          this.optionType = this.optionsets[0];
          this.onChange();
        }
      });
    },
    // vehicle 選擇全部
    selectAll() {
      this.$nextTick(() => {
        if (this.likesAllItems) {
          this.getOptions = [];
        } else {
          this.getOptions = this.optionsList.slice();
        }
      });
    },

    // 查詢 trip list 資料
    submit(setSelect) {
      if (this.$refs.form.validate()) {
        this.checkForm();
        if (this.valid) {
          const query = {
            value: this.optionType.value,
            startTime: moment(this.dates[0]).format('YYYY-MM-DD'),
            endTime: moment(this.dates[1]).format('YYYY-MM-DD'),
            distance: parseInt(this.distance),
          };
          const list = [];
          this.getOptions.forEach((item) => {
            list.push(item);
          });
          query.items = list;
          this.tripLoading = true;
          this.usedTagColor = [];
          ApiService.getTrips(this.$axios, query).then((response) => {
            this.tripLoading = false;
            this.getTripList = response.items;
            this.next();
            this.clearAll();
            // No record
            this.noRecord = false;
            if (this.getTripList.length === 0) {
              this.noRecord = true;
            }

            if (
              setSelect &&
              !(setSelect instanceof PointerEvent) &&
              this.getTripList.length > 0
            ) {
              this.warning(
                this.getTripList.find((item) => item.id === setSelect)
              );
            }
          });
        }
      }
    },
    checkForm() {
      this.valid = true;
      if (!this.optionType.value) {
        this.valid = false;
      }
      if (!this.dates[0]) {
        this.valid = false;
      }
      if (!this.dates[1]) {
        this.valid = false;
      }
      if (this.getOptions === null || this.getOptions.length === 0) {
        this.valid = false;
      }
      if (this.valid) {
        if (this.getOptions !== null) {
          this.getOptions.forEach((item) => {
            this.vehicle.push(item);
          });
        }
      }
    },
    // trip 的 currentPage
    next() {
      this.tripList = [];
      this.tripList = this.getTripList.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },

    nextHistory() {
      this.historyList = [];
      this.historyList = this.getHistoryList.slice(
        (this.currentHistory - 1) * this.perPage,
        this.currentHistory * this.perPage
      );
    },
    getTagColor(index) {
      let c = guageColor[index];
      let i = index;
      while (this.usedTagColor.includes(c)) {
        i = i + 1;
        if (i >= 4) i = 0;
        c = guageColor[i];
      }

      this.usedTagColor.push(c);
      return c;
    },
    // 整理好資料 塞進 warningItem 中
    warning(item) {
      // 判斷是否選擇相同的trips
      let chk = false;
      this.warningItem.forEach((t, index) => {
        if (t.id === item.id) {
          this.returnLatLng(index);
          chk = true;
        }
      });
      if (chk) {
        return false;
      }
      // 判斷warning list 筆數是否大於四個
      if (this.warningItem.length >= 4) {
        this.tripErrorFilter = true;
        this.tripErrorMessage = this.$t('MaxError');
        return false;
      }
      const color = this.getTagColor(this.warningItem.length);
      this.colorIndex++;
      const query = { TripId: item.id };
      ApiService.getTripDetails(this.$axios, query).then((response) => {
        if (response) {
          this.vehicleType = response.vehicleType;
          let mobileLatLng = null;
          if (response.route.ployline.length > 0) {
            mobileLatLng = {
              lat: response.route.ployline[0].lat,
              lng: response.route.ployline[0].lng,
            };
          }
          const warningTypeTotal = this.statisticsWarningType(
            response.warnings.items
          );
          const items = [];
          response.warnings.items.forEach((item, index) => {
            const occurrence = {
              id: item.id + '-' + index,
              rakeOn: item.brakeOn,
              duration: item.duration,
              display: true,
              endSpeed: item.endSpeed,
              endTime: moment(item.endTime).format('YYYY-MM-DD HH:mm:ss'),
              hw: item.hw,
              isAppealApproved: item.isAppealApproved,
              isAppealed: item.isAppealed,
              isDeleted: item.isDeleted,
              isMediaReady: item.isMediaReady,
              isMediaWatched: item.isMediaWatched,
              lat: item.lat,
              leftSignalOn: item.leftSignalOn,
              lng: item.lng,
              mediaFullId: item.mediaFullId,
              mediaStatus: item.mediaStatus,
              minHW: item.minHW,
              rightSignalOn: item.rightSignalOn,
              startSpeed: item.startSpeed,
              startTime: moment(item.startTime).format('YYYY-MM-DD HH:mm:ss'),
              topSpeed: item.topSpeed,
              vehiclePlate: item.vehiclePlate,
              warningTypeText: item.warningTypeText,
              isBSD: item.isBSD,
              warningType: item.warningType,
              addressEn: item.addressEn,
              addressCh: item.addressCh,
            };
            if (this.IsSPW(item.warningType)) {
              occurrence.speedLimit = item.speedLimit;
            }
            items.push(occurrence);
          });

          this.warningItem.push({
            id: item.id,
            accDate: item.accDate,
            distance: item.distance,
            driverGroup: item.driverGroup ? item.driverGroup : '',
            driverName: item.driverName ? item.driverName : '',
            duration: item.duration,
            endTime: item.endTime,
            plate: item.plate,
            startTime: item.startTime,
            vehicleGroup: item.vehicleGroup,
            ployline: response.route.ployline,
            spots: response.route.spots,
            color,
            items,
            mobileLatLng,
            warningTypeTotal,
          });

          this.tab = this.warningItem.length - 1;
        }
      });
    },
    // Set map data
    setMapList(val) {
      this.polyList = [];
      this.markList = [];
      this.historyList = [];
      this.getTripList.forEach((trip) => {
        trip.color = '';
      });
      val.forEach((pItem, i) => {
        // set color
        //! why, totally useless
        // pItem.color = guageColor[i];

        // this.tripList.forEach((trip) => {
        //   if (trip.id === pItem.id) {
        //     trip.color = pItem.color;

        //     if (this.$refs.historyTrip) {
        //       this.$refs.historyTrip.style.backgroundColor = pItem.color;
        //       this.$refs.historyTrip.style.color = '#fff';
        //     }
        //   }
        // });
        this.getTripList.forEach((trip) => {
          if (trip.id === pItem.id) {
            trip.color = pItem.color;

            if (this.$refs.historyTrip) {
              this.$refs.historyTrip.style.backgroundColor = pItem.color;
              this.$refs.historyTrip.style.color = '#fff';
            }
          }
        });
        // set history route
        this.currentPlate = pItem.plate;
        this.historyList = pItem.ployline;
        this.getHistoryList = pItem.ployline;
        this.nextHistory();

        // set map list
        this.polyList.push({
          spots: pItem.spots,
          color: pItem.color,
          ployline: pItem.ployline,
        });

        // ployline
        pItem.spots.forEach((item) => {
          if (item.type === 'startPoint' || item.type === 'finishPoint') {
            this.markList.push({
              id: pItem.id,
              color: pItem.color,
              display: true,
              lat: item.lat,
              lng: item.lng,
              warningType: item.type,
            });
          }
        });
        // markers
        pItem.items.forEach((dItem) => {
          const occurrence = { ...dItem };
          /*
            id: dItem.id,
            display: dItem.display,
            lat: dItem.lat,
            lng: dItem.lng,
            warningTypeText: dItem.warningTypeText,
            duration: dItem.duration,
            topSpeed: dItem.topSpeed,
            startSpeed: dItem.startSpeed,
            endSpeed: dItem.endSpeed,
            mediaFullId: dItem.mediaFullId,
            startTime: dItem.startTime,
            isBSD: dItem.isBSD,
            warningType: dItem.warningType,
          };
          */

          if (
            this.IsSPW(dItem.warningType) &&
            dItem.speedLimit !== null &&
            dItem.speedLimit !== ''
          ) {
            occurrence.speedLimit = dItem.speedLimit;
          }

          this.markList.push(occurrence);
        });
      });
      setTimeout(() => {
        this.next();
        this.updateState();
      }, 500);
    },
    // Update map data
    updateState() {
      this.banner = false;
      if (this.markList.length > 0) {
        this.banner = true;
        this.getMarker(this.markList);
        this.getPloyline(this.polyList);
        this.markList.forEach((item) => {
          if (
            item.id === this.warningItem[this.tab].id &&
            item.warningType === 'startPoint'
          ) {
            this.mobileLatLng(item);
          }
        });
      }
    },

    // 清除所有 warningItem 資料
    clearAll() {
      this.warningItem = [];
      this.getMarker(null);
      this.getPloyline(null);
    },
    // 清除單一 warningItem 資料
    clearWarningTab(index) {
      this.warningItem.splice(index, 1);
      if (this.warningItem.length === 0) {
        this.getMarker(null);
        this.getPloyline(null);
        this.banner = false;
      } else {
        this.$refs.warningList[this.warningItem.length - 1].changeWarningType();
      }
      this.usedTagColor = this.warningItem.map((u) => u.color);
    },
    // 移動到google map 座標
    mobileLatLng(val) {
      this.getMobileLatLng(val);
    },
    // warning list detail
    detailLatLng(warningItem) {
      this.getMobileLatLng({
        /*
        id: val.id,
        lat: val.lat,
        lng: val.lng,
        warningTypeText: val.warningTypeText,
        duration: val.duration,
        topSpeed: val.topSpeed,
        startSpeed: val.startSpeed,
        endSpeed: val.endSpeed,
        mediaFullId: val.mediaFullId,
        bearing: val.bearing,
        speed: val.speed,
        distance: val.distance,
        time: moment(val.time).format('YYYY-MM-DD HH:mm:ss'),
        startTime: val.startTime,
        speedLimit: val.speedLimit,
        vehicleType: this.vehicleType,
        isBSD: val.isBSD,
        warningType: val.warningType,
        */
        ...warningItem,
        time: moment(warningItem.time).format('YYYY-MM-DD HH:mm:ss'),
        vehicleType: this.vehicleType,
      });
    },
    // 返回到 polyLine 座標
    returnLatLng(index) {
      this.tab = index;
      const currentTab = this.$refs.warningList[index];
      currentTab.changeWarningType();

      // set history
      if (this.$refs.historyTrip) {
        this.$refs.historyTrip.style.backgroundColor = currentTab.color;
        this.$refs.historyTrip.style.color = '#fff';
      }

      this.historyList = this.warningItem[index].ployline;
      this.getHistoryList = this.warningItem[index].ployline;
      this.nextHistory();

      this.setSelected(this.warningItem[index].id);
    },
    // 統計 WarningType 數量
    statisticsWarningType(item) {
      const counter = {};
      item.forEach(function (val) {
        const key = val.warningType;
        if (!(key in counter)) {
          counter[key] = { count: 0, label: val.warningTypeText };
        }
        counter[key].count = counter[key].count + 1;
      });
      return counter;
    },
    // 改變 WarningType 顯示隱藏的狀態
    changeWarningType(getValue) {
      this.restorationWarningItem();

      getValue.forEach((items) => {
        this.warningItem[items.index]?.items.forEach((item, index) => {
          if (item.warningType === items.warningType) {
            this.$set(
              this.warningItem[items.index].items[index],
              'display',
              true
            );
          }
        });
      });

      this.setMapList(this.warningItem);
    },
    // 把所有 WarningType 初始化
    restorationWarningItem() {
      this.warningItem.forEach((items) => {
        items.items.forEach((item) => {
          this.$set(item, 'display', false);
        });
      });
    },
    // vehicle 選擇全部
    vehicleSelectAll() {
      this.$nextTick(() => {
        if (this.likesAllItems) {
          this.selectorSwitch = true;
          this.vehicle = [];
        } else {
          this.selectorSwitch = false;
          this.vehicle = [];
          this.vehicles.forEach((item) => {
            this.vehicle.push(item.key);
          });
        }
      });
    },
    reload() {
      const cureentItem = this.warningItem[this.tab];
      this.clearWarningTab(this.tab);
      this.warning(cureentItem);
    },
    // 播放影片功能
    playVideo(value, isBSD) {
      this.media = value;
      this.videoDialog = true;
      this.isBSD = isBSD;
    },
    // jump to selected
    setSelected(val) {
      let num = 0;
      this.getTripList.forEach((item, i) => {
        if (item.id === val) {
          num = i === 0 ? 0 : i / 10;
          this.tripList = i % 10;
        }
      });
      this.currentPage = Math.floor(num) + 1;
      this.next();
      this.nextHistory();
    },
    // return to warning list
    returnTrip() {
      this.historyDrawer = false;
    },
    goHistory() {
      this.historyDrawer = true;
    },
    // emit from google map
    onClickOnRoutePath(pt) {
      if (!this.historyDrawer) return;
      this.currentHistory = 1 + parseInt(pt.index / 10);
      this.nextHistory();
      this.selectedItem = pt.index % 10;
      this.$nextTick(() => {
        document
          .getElementById(
            `historylist-item-${this.historyList[this.selectedItem].id}`
          )
          .scrollIntoView();
      });
    },
    playRoute() {
      if (!this.historyDrawer) {
        return;
      }
      const _this = this;
      if (
        this.playingRouteTimer === undefined ||
        this.playingRouteTimer === null
      ) {
        this.playRouteBtnDisabled = true;
        this.stopRouteBtnDisabled = false;

        const showCurrentSpot = (i) => {
          const indexInTotal = i + _this.perPage * (_this.currentHistory - 1);

          const currentItem = _this.getHistoryList[indexInTotal];
          if (currentItem === undefined || currentItem === null) {
            console.log(
              i,
              _this.perPage,
              _this.currentHistory,
              indexInTotal,
              _this.getHistoryList
            );
          }
          _this.detailLatLng(currentItem);

          _this.$nextTick(() => {
            document
              .getElementById(`historylist-item-${_this.historyList[i].id}`)
              .scrollIntoView();
          });

          return indexInTotal + 1 >= _this.getHistoryList.length;
        };
        if (this.selectedItem === undefined) {
          this.selectedItem = 0;
        }
        if (showCurrentSpot(this.selectedItem)) {
          this.playRouteBtnDisabled = false;
          this.stopRouteBtnDisabled = true;
          return;
        }

        this.playingRouteTimer = setInterval(() => {
          if (!this.historyDrawer) {
            _this.stopRoute();
            return;
          }
          _this.selectedItem++;
          if (_this.selectedItem >= _this.perPage) {
            _this.selectedItem = 0;
            _this.currentHistory++;
            _this.nextHistory();
          }
          if (showCurrentSpot(_this.selectedItem)) {
            _this.stopRoute();
          }
        }, 1000);
      }
    },
    stopRoute() {
      console.log('stop playing route');
      clearInterval(this.playingRouteTimer);
      this.playingRouteTimer = null;
      this.playRouteBtnDisabled = false;
      this.stopRouteBtnDisabled = true;
    },
  },
};
