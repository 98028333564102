
import ApiService from '@/services/api-service.js';
import moment from 'moment';
import geofenceMixin from '@/plugins/geofenceMixin';
import GlobalDataTable from '~/components/global/DataTable.vue';
export default {
  components: {
    GlobalDataTable,
    VueJsonToCsv: () => {
      if (process.client) {
        return import('vue-json-to-csv');
      }
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  mixins: [geofenceMixin],
  data() {
    return {
      disabledBefore(date) {
        return (
          date > new Date() ||
          date < new Date(new Date().setFullYear(new Date().getFullYear() - 1))
        );
      },
      disabledAfter() {
        return new Date();
      },
      dates: [
        // new Date(new Date().setDate(new Date().getDate() - 1)),
        new Date(moment().startOf('day').unix() * 1000),
        new Date(new Date().setDate(new Date().getDate())),
      ],
      vehicle: '',
      vehicleOptionList: [],
      area: '',
      areaOptionList: [],

      search: '',
      geofenceData: [],
      mapDialog: false,
      mapDialogGoogleMapUrl: '',
    };
  },
  computed: {
    selectAllVehicles() {
      return this.vehicle.length === this.vehicleOptionList.length;
    },
    selectSomeVehicles() {
      return this.vehicle.length > 0 && !this.selectAllVehicles;
    },
    icon() {
      return this.selectAllVehicles
        ? 'mdi-close-box'
        : this.selectSomeVehicles
        ? 'mdi-minus-box'
        : 'mdi-checkbox-blank-outline';
    },
    cAreaText() {
      return this.areaOptionList.reduce((p, cur) => {
        p[cur.name] = cur.meta;
        return p;
      }, {});
    },
    cAreaButtonDisable() {
      return ['all', ''].includes(this.area);
    },
    cAreaCoord() {
      for (const a of this.areaOptionList) {
        if (a.name === this.area) {
          return a.geometry.coordinates[0];
        }
      }
      return null;
    },
    cAreaDisplayName() {
      for (const a of this.areaOptionList) {
        if (a.name === this.area) {
          return a.meta[this.$store.state.app.locale] || a.name;
        }
      }
      return null;
    },
    cAreaOptionList() {
      return [
        { text: '', value: 'all' },
        ...this.areaOptionList.map((a) => {
          return {
            text: a.meta[this.$store.state.app.locale] || a.name,
            value: a.name,
          };
        }),
      ];
    },
    langForDatepicker() {
      return this.$i18n.locale === 'zh-Hant' ? 'zh-cn' : this.$i18n.locale;
    },
    headers() {
      return [
        { text: this.$t('vehicle'), value: 'vehicle' },
        { text: this.$t('GeoFencing.area'), value: 'area' },
        { text: this.$t('GeoFencing.inOutTime'), value: 'epoch' },
        /*
        { text: this.$t('GeoFencing.accOn'), value: 'accOn' },
        { text: this.$t('GeoFencing.speed'), value: 'speed' },
        { text: this.$t('GeoFencing.lat'), value: 'lat' },
        { text: this.$t('GeoFencing.lon'), value: 'lon' },
        */
        { text: this.$t('GeoFencing.inOut'), value: 'detect' },
      ];
    },
    downloadList: {
      get() {
        return this.geofenceData.map((item) => {
          return this.headers.reduce((prev, head) => {
            if (head.value === 'epoch')
              prev[head.text] = this.momentformatDate(item[head.value]);
            else if (head.value === 'area')
              prev[head.text] =
                item[head.value] in this.cAreaText
                  ? this.cAreaText[item[head.value]][
                      this.$store.state.app.locale
                    ]
                  : '';
            else if (head.value === 'detect') {
              prev[head.text] =
                this.$t(`GeoFencing.${item[head.value]}`) ||
                item[head.value] ||
                '';
            } else prev[head.text] = item[head.value] ?? '';
            return prev;
          }, {});
        });
      },
    },
  },
  watch: {
    // Change company
    '$store.state.app.company'(_val, old) {
      if (_val !== old && old !== '') {
        console.log(`company ${old} => ${_val}, init`);
        this.initAllFilters().then();
      }
    },
    area(_val) {
      this.getData();
    },
    vehicle(val) {
      console.log(val);
      this.getData();
    },
    dates(_val) {
      // console.log(val);
      this.getData();
    },
  },
  async mounted() {
    await this.initAllFilters();
  },
  methods: {
    doSelectAllVehicles() {
      this.vehicle = this.selectAllVehicles
        ? []
        : this.vehicleOptionList.slice();
    },
    async initAllFilters() {
      await this.getAreaOptions();

      const proms = [this.getData()];
      proms.push(this.getVehicleOptions());
      await Promise.all(proms);
    },
    async getVehicleOptions() {
      const resp = await ApiService.getTripOptions(this.$axios);

      this.vehicleOptionList = resp.items.filter(
        (o) => o.name === 'LicencePlae'
      )[0].value;

      this.vehicle = this.vehicleOptionList.slice();
    },
    async getAreaOptions() {
      /*
      const myNewInstance = this.$axios.create({
        baseURL: 'https://geofence-service-data.azurewebsites.net',
        timeout: 30000,
        headers: { Company: this.$store.state.app.company },
      });
      */

      this.areaOptionList = await this.fetchGeofences();
    },
    async getData() {
      /*
      const myNewInstance = this.$axios.create({
        baseURL: 'https://geofence-service-data.azurewebsites.net',
        timeout: 5 * 60 * 1000,
        headers: { Company: this.$store.state.app.company },
      });
      */

      const myNewInstance = this.$axios;

      let paramsVehicleId = '';

      if (this.vehicle !== null && this.vehicle !== '') {
        // paramsVehicleId = `&v=${this.vehicle.split(' ')[0]}`;
        const selected = this.vehicle.map((v) => v.split(' ')[0]).join(',');
        paramsVehicleId = `&v=${selected}`;
      }

      let paramsDateRange = '';
      if (this.dates) {
        const sd = moment(this.dates[0]).unix();
        const ed = moment(this.dates[1]).unix();
        paramsDateRange = `&sd=${sd}&ed=${ed}`;
      }

      let paramsArea = '';
      if (this.area !== '' && this.area !== 'all') {
        paramsArea = `&area=${this.area}`;
      }

      this.dataTableShowLoading = true;
      this.geofenceData = (
        await myNewInstance.$get(
          `/api/geofencing?${paramsVehicleId}${paramsDateRange}${paramsArea}`
        )
      ).map((r) => {
        // r.vehicle = r.PartitionKey.split(':')[1];
        r.vehicle = r.plate;
        // r.area = r.hook;
        // r.id = r.eid;
        // r.epoch = r.epoch && r.epoch.length > 0 ? r.epoch[0] * 1000 : 0;
        r.epoch = r.epoch * 1000;

        /*
        const { accOn, speed } = JSON.parse(r.fields_jsonstr);
        r.speed = speed;
        r.accOn = accOn;
        const { coordinates } = JSON.parse(r.object_jsonstr);
        r.lat = coordinates[1];
        r.lon = coordinates[0];
        */
        return r;
      });
      this.dataTableShowLoading = false;
    },
    showMapForArea() {
      // console.log(item);
      // Reset src path and center string
      const googleMapUrl = 'https://maps.googleapis.com/maps/api/staticmap?';

      // Pop Up Window
      this.mapDialog = true;

      const params =
        '&size=660x460&key=AIzaSyDnCqlHgVv_C23CFiIYhS20l3qfP4BIxUk';

      const polygon = this.cAreaCoord;
      const polygonUrlParams = `&path=fillcolor:0xff000066%7ccolor:0xff0000ff%7cweight:5%7c${polygon
        .map((m) => `${m[1]},${m[0]}`)
        .join('%7c')}`;
      /*
      const bounds = new window.google.maps.LatLngBounds();
      for (const p of polygon) {
        bounds.extend({ lat: p[0], lng: p[1] });
      }
      const center = bounds.getCenter();

      const map = new window.google.maps.Map(document.createElement('div'));
      map.fitBounds(bounds);
      const zoomLevel = map.getZoom();
*/
      this.mapDialogGoogleMapUrl = `${googleMapUrl}${polygonUrlParams}${params}`;
    },
  },
};
