
import moment from 'moment';
import { vehicleSVG } from '@/plugins/vehiclesvg';
import mapMixin from '@/plugins/mapMixin';
let lastOpenedInfoWindow = '';

export default {
  mixins: [mapMixin],
  props: {
    center: {
      type: Object,
      default: () => ({
        lat: Number,
        lng: Number,
      }),
    },
    zoom: {
      type: Number,
      default: 12,
    },
    showPlate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    closeDialog: () => {},
    videoTipsDialog: false,
    videoTipsi18n: 'historyVideoTips',
    map: Object,
    mapID: '',
    markers: [],
    polylines: [],
    locations: [],
    reductionId: '',
    reductionStatus: '',
    currentVehicle: [],
    historyMarkers: [],
    currentPlate: '',
    currentMediaId: '',

    licensePlateInfoWindows: [],
  }),
  computed: {
    vehicleList: {
      get() {
        return this.$store.state.app.vehicleList;
      },
    },
    getVehicle: {
      get() {
        return this.$store.state.app.getVehicle;
      },
    },
    checkType: {
      get() {
        return this.$store.state.app.checkType;
      },
    },
    getPloyline: {
      get() {
        return this.$store.state.app.getPloyline;
      },
    },
    getMarker: {
      get() {
        return this.$store.state.app.getMarker;
      },
    },
    getStartAndEndMarker: {
      get() {
        return this.$store.state.app.getStartAndEndMarker;
      },
    },
    getMobileLatLng: {
      get() {
        return this.$store.state.app.getMobileLatLng;
      },
    },
  },
  watch: {
    showPlate(val) {
      val ? this.showPlateOnMap() : this.hidePlateOnMap();
    },
    '$store.state.app.locale'(val) {
      this.$i18n.locale = val;
      if (this.currentVehicle.isActive) {
        this.mobileLatLng(this.currentVehicle, true);
      }
    },
    // set markers
    vehicleList(val) {
      if (val) {
        this.setMarker(val);
        this.showPlate ? this.showPlateOnMap() : this.hidePlateOnMap();
      }
    },
    // get vehicle info
    getVehicle(val) {
      if (val) {
        this.mobileLatLng(val, true);
        this.currentVehicle = val;
      }
    },
    getPloyline(val) {
      this.setPolyine(val);
    },
    getMarker(val) {
      this.setTripMarker(val);
    },
    getMobileLatLng(val) {
      this.tripMobileLatLng(val);
    },
  },
  mounted() {
    this.initMap();
  },
  methods: {
    showPlateOnMap() {
      for (const marker of this.markers) {
        const infowindow = new window.google.maps.InfoWindow({
          content: `
              <div id="content">
                <div style="text-align:center;">
                  <span>${marker.getTitle()}<span>
                </div>
              </div>
                `,
          maxWidth: 280,
        });
        infowindow.open(this.map, marker);
        this.licensePlateInfoWindows.push(infowindow);
      }
    },
    hidePlateOnMap() {
      for (const w of this.licensePlateInfoWindows) {
        w.close();
      }
    },
    initMap() {
      this.map = new window.google.maps.Map(document.getElementById('map'), {
        center: this.center,
        zoom: this.zoom,
        maxZoom: 35,
        minZoom: 3,
        streetViewControl: false,
        mapTypeControl: false,
        // fullscreenControl: this.fullscreenControl,
        // zoomControl: this.zoomControl,
      });
      this.customControls(this.map);
    },
    // set marker
    setMarker(locations) {
      this.setMapOnMarkersAll(null);
      this.markers = [];
      locations.forEach((location) => {
        const image = {
          url: require('@/assets/images/' + location.status + '.png'),
        };
        const marker = new window.google.maps.Marker({
          mapTypeId: {
            id: location.plate + location.model,
            status: location.status,
          },
          title: `${location.plate} [${location.department}]`,
          position: { lat: location.lat, lng: location.lng },
          icon: image,
        });

        window.google.maps.event.addListener(marker, 'click', () => {
          this.showInfo(location, marker);
          this.mobileLatLng(location, false);
          this.$emit('setSelected', {
            plate: location.plate,
            liveVideoPlate: location.liveVideoPlate,
          });
        });

        this.markers.push(marker);
      });

      const trafficLayer = new window.google.maps.TrafficLayer();

      trafficLayer.setMap(this.map);

      this.setMapOnMarkersAll(this.map);
    },
    // show info window
    showInfo(location, marker) {
      const ref = this;
      this.currentPlate = location.liveVideoPlate;
      let showplay = 'none';
      if (this.$store.state.app.liveStreaming) {
        showplay = '';
      }
      let showhistory = 'none';
      if (this.$store.state.app.historyVideo) {
        showhistory = '';
      }

      const videoRow = location?.isVideoOnline
        ? `
        <tr>
          <th align="left" style="display: ${showplay}">
            <button id="playMonitor" class="vBtn" >
              <img class="play-icon" src="https://earth.google.com/images/kml-icons/track-directional/track-4.png" />
              ${this.$t('LiveStreaming')}
            </button>
          </th>
          <th align="right" style="display: ${showhistory}">
            <button id="playHistory" class="vBtn" >
              <img class="play-icon" src="https://earth.google.com/images/kml-icons/track-directional/track-4.png" />
              ${this.$t('HistoryVideo')}
            </button>
          </th>
        </tr>
      `
        : '';

      const i18nTag = {
        online: 'Live.OnlineTip',
        offline: 'Live.OfflineTip',
        stopped: 'Live.StoppedTip',
      };

      const infowindow = new window.google.maps.InfoWindow({
        content: `
          <div id="content">
            <div style="text-align:center;">
              <h3>${location.plate}&nbsp;[${location.department}]<h3>
            </div>
            <hr>
            <table style="width:100%">
              <tr>
                <th align="left">${this.$t('Brand')}:</th>
                <td align="left">${location.brand}</td>
              </tr>
              <tr>
                <th align="left">${this.$t('Model')}:</th>
                <td align="left" >${location.model}</td>
              </tr>
              <tr>
                <th align="left">${this.$t('Driver')}:</th>
                <td align="left" >${location.driver || ''}</td>
              </tr>
              <tr>
                <th align="left">${this.$t('Running_Status')}:</th>
                <td align="left" >${this.$t(i18nTag[location.status])}</td>
              </tr>
              <tr>
                <th align="left">${this.$t('lastLocationUpdateTime')}:</th>
                <td align="left">${ref.formatDate(
                  location.lastRunUpdatedAt
                )}</td>
              </tr>
              ${videoRow}
            </table>
          </div>
                  `,
        maxWidth: 280,
      });

      if (lastOpenedInfoWindow) {
        lastOpenedInfoWindow.close();
      }
      infowindow.open(this.map, marker);
      lastOpenedInfoWindow = infowindow;

      // window.playMonitor = function (value) {
      //   this.playMonitor(value);
      // };

      document.addEventListener('click', (event) => {
        if (event.target.id && event.target.id === 'playMonitor') {
          if (location.tBoxProviderId === 9) {
            this.playTTXLiveVideo(location.liveVideoPlate);
          } else if (location.liveVideoPlate === this.currentPlate) {
            this.playMonitor(location.liveVideoPlate);
          }
        }
        if (event.target.id && event.target.id === 'playHistory') {
          if (location.tBoxProviderId === 9) {
            this.playTTXHistory(location.liveVideoPlate);
          } else if (location.liveVideoPlate === this.currentPlate) {
            this.playHistory(location.liveVideoPlate);
          }
        }
      });
    },
    mobileLatLng(mobileLatLngValue, chk) {
      this.map.panTo({
        lat: mobileLatLngValue.lat,
        lng: mobileLatLngValue.lng,
      });
      this.touchMarker(mobileLatLngValue, chk);
    },
    touchMarker(mobileLatLngValue, chk) {
      const markerID = mobileLatLngValue.plate + mobileLatLngValue.model;
      for (let i = 0; i < this.markers.length; i++) {
        if (this.markers[i].mapTypeId.id === markerID) {
          this.markers[i].setIcon({
            url: require('@/assets/images/selectedMarkIcon.png'),
          });
          this.markers[i].setAnimation(window.google.maps.Animation.BOUNCE);
          if (chk) {
            this.showInfo(mobileLatLngValue, this.markers[i]);
          }
        } else if (this.markers[i].mapTypeId.id === this.reductionId) {
          this.markers[i].setAnimation(null);
          this.markers[i].setIcon({
            url: require('@/assets/images/' + this.reductionStatus + '.png'),
          });
        }
      }
      this.reductionId = markerID;
      this.reductionStatus = mobileLatLngValue.status;
    },
    setMapOnMarkersAll(map) {
      this.markers.forEach((item) => {
        item.setMap(map || null);
      });
    },
    setMapOnPolylinesAll(map) {
      this.polylines.forEach((item) => {
        item.setMap(map || null);
      });
    },
    // ? 2 times

    // ? 1 trip warning -> warningItem ->  setMapList
    // ? 2 trip warning -> warningItem -> setMapList -> next
    // ? 2 tripwarninglist created , emit changewarningtype -> trip setMapList
    // ? when close, trip clearWarningTab
    // trip ployline
    setPolyine(val) {
      this.setMapOnPolylinesAll(null);
      this.polylines = [];
      if (val) {
        val.forEach((item) => {
          const polyline = new window.google.maps.Polyline({
            path: item.ployline,
            geodesic: true,
            strokeColor: item.color, // 折線顏色
            strokeOpacity: 1.0, // 折線透明度
            strokeWeight: 4, // 折線粗度
          });
          this.polylines.push(polyline);
          polyline.setMap(this.map);
          window.google.maps.event.addListener(polyline, 'click', (h) => {
            const latlng = h.latLng;
            const needle = {
              minDistance: 9999999999, // silly high
              index: -1,
              latlng: null,
            };
            polyline.getPath().forEach((routePoint, index) => {
              const dist =
                window.google.maps.geometry.spherical.computeDistanceBetween(
                  latlng,
                  routePoint
                );
              if (dist < needle.minDistance) {
                needle.minDistance = dist;
                needle.index = index;
                needle.latlng = routePoint;
              }
            });
            console.log(`${latlng.lat()} ${latlng.lng()}`);
            this.$emit('onClickOnRoutePath', needle);
          });
        });
      }
    },
    // trip marker
    setTripMarker(val) {
      this.setMapOnMarkersAll(null);
      this.markers = [];
      if (val) {
        val.forEach((item) => {
          if (item.display) {
            let image = {};
            try {
              if (!['startPoint', 'finishPoint'].includes(item.warningType)) {
                image = {
                  url: require('@/assets/images/' + item.warningType + '.png'),
                };
              } else {
                // image = `https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=${item.warningType === 'startPoint' ? 'A' : 'B' }|${item.color.substring(1)}`;
                image = `https://mts.googleapis.com/vt/icon/name=icons/spotlight/spotlight-waypoint-${
                  item.warningType === 'startPoint' ? 'a' : 'b'
                }.png&text=${
                  item.warningType === 'startPoint' ? 'A' : 'B'
                }&psize=16&font=fonts/Roboto-Regular.ttf&color=ff000099&ax=44&ay=48&scale=1`;
              }
            } catch (err) {
              image = {
                url: require('@/assets/images/ERROR.png'),
              };
            }
            const marker = new window.google.maps.Marker({
              mapTypeId: item.id,
              // position: new window.google.maps.LatLng(item.lat, item.lng),
              position: { lat: item.lat, lng: item.lng },
              icon: image,
            });
            if (!['startPoint', 'finishPoint'].includes(item.warningType)) {
              window.google.maps.event.addListener(marker, 'click', () => {
                this.showTripInfo(item, marker);
              });
            }
            this.markers.push(marker);
            marker.setMap(this.map);
          }
        });
      }
    },
    // trip  map infowindow
    // trip info
    showTripInfo(location, marker) {
      // show display validation
      let showplay = 'none';
      if (location.mediaFullId) {
        showplay = '';
        this.currentMediaId = location.mediaFullId;
      }

      let speedlimitTr = '';
      if (
        location.speedLimit !== null &&
        location.speedLimit !== '' &&
        location.speedLimit !== undefined
      ) {
        speedlimitTr = `
          <tr>
            <th align="left">${this.$t('speedLimit')}:</th>
            <td align="left">${location.speedLimit}</td>
          </tr>
        `;
      }
      const infowindow = new window.google.maps.InfoWindow({
        content: `
            <div id="content">
              <div style="text-align:center;">
                <h3>${this.$t(location.warningType)}<h3>
              </div>
              <hr>
                <table style="width:100%">
                  <tr>
                    <th align="left" style="width: 120px">${this.$t(
                      'startTime'
                    )}:</th>
                    <td align="left">${location.startTime ?? ''}</td>
                  </tr>
                  <tr>
                    <th align="left">${this.$t('duration')}:</th>
                    <td align="left">${
                      location.duration ? location.duration : ''
                    }</td>
                  </tr>
                  ${speedlimitTr}
                  <tr>
                    <th align="left">${this.$t('startSpeed')}:</th>
                    <td align="left" >${location.startSpeed ?? ''} </td>
                  </tr>
                  <tr>
                    <th align="left">${this.$t('endSpeed')}:</th>
                    <td align="left" >${location.endSpeed ?? ''} </td>
                  </tr>
                  <tr>
                    <th align="left">${this.$t('topSpeed')}:</th>
                    <td align="left">${location.topSpeed ?? ''} </td>
                  </tr>
                  <tr>
                    <th align="left">${this.$t('address')}:</th>
                    <td align="left">${
                      (this.$store.state.app.locale === 'en'
                        ? location.addressEn
                        : location.addressCh) ?? ''
                    } </td>
                  </tr>
                  <tr style="display: ${showplay}">
                    <th align="left">
                      <button id="playVideo" class="vBtn" >
                        <img class="play-icon" src="https://earth.google.com/images/kml-icons/track-directional/track-4.png" />
                        ${this.$t('PlayVideo')}
                      </button>
                    </th>
                    <td align="left">
                    </td>
                  </tr>
                </table>
            </div>
                `,
        maxWidth: 500,
      });

      if (lastOpenedInfoWindow) {
        lastOpenedInfoWindow.close();
        document.removeEventListener('click', this.lastClickCallback);
      }
      infowindow.open(this.map, marker);
      lastOpenedInfoWindow = infowindow;

      // click event
      const clickCallback = this.emitPlayVideo(
        'playVideo',
        this.currentMediaId,
        location.isBSD
      );
      document.addEventListener('click', clickCallback);
      this.lastClickCallback = clickCallback;
    },
    // ? when click warning row in warning list of a trip after click a trip in Trip page
    // trip mobile lat,lng
    tripMobileLatLng(mobileLatLngValue) {
      this.historyMarkers.forEach((item) => {
        item.setMap(null);
      });
      this.historyMarkers = [];
      this.map.panTo({
        lat: mobileLatLngValue.lat,
        lng: mobileLatLngValue.lng,
      });
      this.map.setZoom(this.zoom);

      if (
        !['startPoint', 'finishPoint'].includes(mobileLatLngValue.warningType)
      ) {
        this.markers.forEach((item) => {
          if (item.mapTypeId === mobileLatLngValue.id) {
            this.showTripInfo(mobileLatLngValue, item);
            this.map.setZoom(16);
          }
        });
      }

      // if (!mobileLatLngValue.warningTypeText) {
      if (!mobileLatLngValue.warningType) {
        this.historyMarkers.forEach((item) => {
          item.setMap(null);
        });
        this.historyMarkers = [];
        const tempMarker = new window.google.maps.Marker({
          mapTypeId: mobileLatLngValue.id,
          position: {
            lat: mobileLatLngValue.lat,
            lng: mobileLatLngValue.lng,
          },
          icon: {
            // path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
            // anchor: new window.google.maps.Point(0, 2.5),
            // scale: 4,
            // strokeWeight: 3,
            // rotation: mobileLatLngValue.bearing,
            ...vehicleSVG[mobileLatLngValue.vehicleType](
              mobileLatLngValue.bearing
            ),
            fixedRotation: true,
            offset: '0%',
            strokeColor: 'blue',
            fillColor: 'blue',
          },
        });
        this.historyMarkers.push(tempMarker);
        tempMarker.setMap(this.map);

        this.showHistoryInfo(mobileLatLngValue, tempMarker);
        this.map.setZoom(18);
      }
    },
    showHistoryInfo(location, marker) {
      const infowindow = new window.google.maps.InfoWindow({
        content: `
            <div id="content">
                <table style="width:100%">
                <tr>
                    <th align="left">${this.$t('Trip.time')}:</th>
                    <td align="left" >${location.time} </td>
                  </tr>
                  <tr>
                    <th align="left">${this.$t('Trip.speed')}:</th>
                    <td align="left" >${location.speed} </td>
                  </tr>
                  <tr>
                    <th align="left">${this.$t('Trip.distance')}:</th>
                    <td align="left" >${location.distance} </td>
                  </tr>
                </table>
            </div>
                `,
        maxWidth: 280,
      });
      if (lastOpenedInfoWindow) {
        lastOpenedInfoWindow.close();
      }
      infowindow.open(this.map, marker);
      lastOpenedInfoWindow = infowindow;
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },

    playTTXHistory(plate) {
      this.videoTipsi18n = 'historyVideoTips';
      this.videoTipsDialog = true;
      this.closeDialog = () => {
        this.videoTipsDialog = false;
        this.$emit('playTTXHistory', plate);
      };
    },
    playTTXLiveVideo(plate) {
      this.videoTipsi18n = 'liveVideoTips';
      this.videoTipsDialog = true;
      this.closeDialog = () => {
        this.videoTipsDialog = false;
        this.$emit('playTTXLiveVideo', plate);
      };
    },
    // LiveInformation play realtime video event
    playMonitor(value) {
      this.videoTipsi18n = 'liveVideoTips';
      this.videoTipsDialog = true;
      this.closeDialog = () => {
        this.videoTipsDialog = false;
        this.$emit('playMonitor', value);
      };
    },
    playHistory(value) {
      this.videoTipsi18n = 'historyVideoTips';
      this.videoTipsDialog = true;
      this.closeDialog = () => {
        this.videoTipsDialog = false;
        this.$emit('playHistory', value);
      };
    },
    // Trip map infowindow click play video
    emitPlayVideo(targetId, fullId, isBSD) {
      return (event) => {
        if (event.target.id && event.target.id === targetId) {
          this.$emit('playVideo', fullId, isBSD);
        }
      };
    },
  },
};
