
import moment from 'moment';
import ApiService from '@/services/settings-service.js';

export default {
  filters: {
    formatYear(date) {
      return moment(date).format('YYYY');
    },
  },
  props: {
    value: Boolean,
    updateValue: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      UpdateAuth: false,
      date: null,
      menu: false,
      isAdd: false,
      // Compnay parameters
      id: '',
      code: '',
      name: '',
      timezone: '',
      isActive: false,
      isDistributor: false,
      apiKey: '',
      contactName: '',
      contactPhone: '',
      contactEmail: '',
      // test
      departmentOption: [],
      checkDepartmentOption: [],
      distributorOption: [],
      distributor: '',
      filterInput: '',
      filterTypeInput: '',
      functions: [],
      functionList: [],
      rules: {
        required: (value) => {
          if (Array.isArray(value) && value.length === 0) {
            return this.$t('Required');
          }
          return !!value || this.$t('Required');
        },
      },
    };
  },
  computed: {
    // Active() {
    //   return [
    //     { text: this.$t('Setting.Active'), value: true },
    //     { text: this.$t('Setting.Inactive'), value: false },
    //   ];
    // },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
    show(val) {
      this.$refs.form.resetValidation();
      this.code = '';
      this.name = '';
      this.timezone = '';
      this.isActive = false;
      this.isDistributor = false;
      this.distributor = '';
      this.apiKey = '';
      this.contactName = '';
      this.contactPhone = '';
      this.contactEmail = '';
      this.functions = [];
      if (val) {
        if (Object.keys(this.updateValue).length === 0) {
          this.isAdd = true;
        } else {
          // console.log(this.updateValue);
          this.id = this.updateValue.id;
          this.code = this.updateValue.code;
          this.name = this.updateValue.name;
          this.timezone = this.updateValue.timeZone;
          this.isActive = this.updateValue.isActive;
          this.isDistributor = this.updateValue.isDistributor;
          this.distributor = this.updateValue.distributor;
          this.apiKey = this.updateValue.apiKey;
          this.contactName = this.updateValue.contactName;
          this.contactPhone = this.updateValue.contactPhone;
          this.contactEmail = this.updateValue.contactEmail;
          this.functions = this.updateValue.companyFunctions;
        }
      } else {
        this.isAdd = false;
        this.$emit('clearUpdateValue');
      }
    },
    filterInput(value) {
      this.departmentOption = this.checkDepartmentOption;
      this.departmentOption = this.departmentOption.filter((post) => {
        return post.text.toLowerCase().trim().includes(value.toLowerCase());
      });
    },
    isDistributor(val) {
      if (val) {
        this.distributor = '';
      }
    },
    '$store.state.app.userRole'() {
      this.checkAuth();
    },
  },
  created() {
    this.getCompanyGroups();
    this.getDistributor();
    this.getFunctionList();
    this.checkAuth();
  },
  methods: {
    checkAuth() {
      this.UpdateAuth = false;
      const val = this.$store.state.app.userRole;
      if (val === 'HQ') {
        this.UpdateAuth = true;
      }
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    getCompanyGroups() {
      ApiService.getCompanyGroups(this.$axios)
        .then((response) => {
          response.items.forEach((element) => {
            this.departmentOption.push({
              text: element.name,
              value: element.description,
            });
          });
        })
        .catch({});
      this.checkDepartmentOption = this.departmentOption;
    },
    getFunctionList() {
      ApiService.getFunctions(this.$axios)
        .then((response) => {
          response.forEach((item) => {
            this.functionList.push(item.functionName);
          });
        })
        .catch({});
    },
    getDistributor() {
      ApiService.getDistributorList(this.$axios)
        .then((response) => {
          this.distributorOption = response.distributorCompanies;
          // response.items.forEach((element) => {
          //   this.departmentOption.push({
          //     text: element.name,
          //     value: element.description,
          //   });
          // });
        })
        .catch({});
    },
    submit() {
      if (this.$refs.form.validate()) {
        const query = {
          code: this.code,
          name: this.name,
          timeZone: this.timezone,
          isActive: this.isActive,
          isDistributor: this.isDistributor,
          distributor: this.distributor,
          apiKey: this.apiKey,
          contactName: this.contactName,
          contactPhone: this.contactPhone,
          contactEmail: this.contactEmail,
          companyFunctions: this.functions,
        };
        if (this.isAdd) {
          // console.log('add');
          // console.log(query);
          ApiService.addCompanies(this.$axios, query)
            .then((response) => {
              if (response.success) {
                this.show = false;
              }
              this.$emit('forceRerenderOnSubmit', response);
            })
            .catch((error) => {
              console.log(error);
            });
          // Only Company Settings need this. Adding a new entry cannot
          // let the api to retreive latest API. Reload required.
          // window.location.reload();
        } else {
          // console.log('update');
          query.id = this.id;
          // console.log(query);
          ApiService.updateCompanies(this.$axios, query)
            .then((response) => {
              if (response.success) {
                this.show = false;
              }
              this.$emit('forceRerenderOnSubmit', response);
            })
            .catch((error) => {
              console.log(error);
            });
        }
        // this.$emit('forceRerenderOnSubmit');
        this.show = false;
      }
    },
  },
};
